export default defineNuxtRouteMiddleware(async () => {
  const partnerStore = usePartnerStore();

  const partner = partnerStore.getBuyoutPartner;

  if (partner?.is_manufacturer_page) {
    if ((partner?.seo_name=="samsung" || partner?.parent?.seo_name=="samsung")) {
      setPageLayout("samsung-default");
    }

    if ((partner?.seo_name=="xiaomi" || partner?.parent?.seo_name=="xiaomi")) {
      setPageLayout("xiaomi-default");
    }
    return;
  }

  setPageLayout("header-footer");
  return;
});
